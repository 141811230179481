import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import matter from "gray-matter";
import "./BlogPost.css";

const BlogPost = () => {
  const { postName } = useParams();
  const [content, setContent] = useState("");
  const [frontMatter, setFrontMatter] = useState({});

  useEffect(() => {
    import(`../posts/${postName}.md`)
      .then((res) => fetch(res.default))
      .then((response) => response.text())
      .then((text) => {
        const { data, content } = matter(text);
        setFrontMatter(data);
        setContent(content);
      });
  }, [postName]);

  return (
    <div className="blog-post-container">
      <h1 className="blog-post-title">{frontMatter.title}</h1>
      <p className="blog-post-meta">
        {frontMatter.date} • Written by Simon Rotzer
      </p>
      {frontMatter.image && (
        <img
          src={`/${frontMatter.image}`}
          alt={frontMatter.title}
          className="blog-post-image"
        />
      )}
      <p className="blog-post-description">{frontMatter.description}</p>
      <div className="blog-post-content">
        <ReactMarkdown>{content}</ReactMarkdown>
      </div>
      

    </div>
  );
};

export default BlogPost;