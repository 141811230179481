import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import About from './components/About';
import Contact from './components/Contact';
import PrivacyPolicy from './components/PrivacyPolicy';
import Header from './components/Header';
import Footer from './components/Footer';
import BlogPost from './components/BlogPost';
import ReactPixel from 'react-facebook-pixel';
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    // Initialize Facebook Pixel
    ReactPixel.init('945486977414252'); // Replace with your actual Pixel ID
    ReactPixel.pageView(); // Track the page view

    // Optionally track custom events or page-specific events
  }, []);

  return (
    <Router basename="/">
      <Header />
      <Switch>
        {/* Redirect from root path to /about */}
        <Redirect exact from="/" to="/about" />
        <Route path="/about" component={About} />
        <Route path="/contact" component={Contact} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/blog/:postName" component={BlogPost} />
        {/* Fallback for unmatched routes */}
        <Redirect to="/about" />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;